<template>
  <div class="columns has-background-white">
    <div class="column">    
    <h1 class="title is-size-5">Get Started on <Brand /></h1>
      <p class="subtitle is-size-6"><Brand type="text" /> lets you earn cash back when you make purchases at home or abroad.</p>
    
      <div class="buttons">
        <button 
          class="button is-fullwidth is-info"
          @click="go('/signup?t=member')"
        >
          I want to earn cash on purchases
        </button>
        <button 
          class="button is-fullwidth"
          @click="go('/organization-intro')"
        >
          I want to list a deal for my business
        </button>
      </div>
      <p class="subtitle is-size-6">
        Already member? 
        <a @click="go('/login')">Login</a>
      </p>
    </div>
    <div class="column has-background-info has-text-white" style="padding:40px;">
      <h1 class="title has-text-white"><Brand /></h1>
      <p class="subtitle has-text-white">Discover the best of local anywhere.</p>
    </div>
  </div>
</template>

<script>
import MarketReferrerMixin from "@/mixins/MarketReferrerMixin.js";
import Brand from "@/components/Brand.vue";

export default {
  mixins: [MarketReferrerMixin],
  components: {
    Brand
  },
  data() {
    return {
      submitting: false,
    }
  },
  mounted() {
    this.updateMarketReferral()

    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/")
    } 
  },
  methods: {
    go(path) {
      this.$router.push(path)
    },
  },
}
</script>
